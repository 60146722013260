.btn {
	@include transition();
	border-radius:0;
	border:0;

	&:hover {
		border-bottom:0;
	}

	&-branded {
		background-color: $colour-primary;
		color: #fff;

		&:hover,&:active,&:focus {
			background-color:$colour-secondary;
			color: #fff;
		}

		& > .btn-label {

		}

	}

	&-labeled {
		padding-top: 0;
		padding-bottom: 0;
	}
	.btn-label {
		position: relative;
		left: -12px;
		display: inline-block;
		padding: 6px 12px;
		background-color: $colour-secondary;
		color: $colour-primary;
		border-radius:0;
		font-size:16px;
	}

	&-blue {
		background-color: $colour-primary;
		color: #fff;
		line-height:22px;

		&:hover,&:active,&:focus {
			background-color: darken($colour-primary,10%);
			color: #fff;
		}
	}
}