/* ======================================================== */
/* == Responsive Snap-point Variables ===================== */
/* ======================================================== */

$screen-xl: 1500px;
$screen-l: 1200px;
$screen-m: 992px;
$screen-s: 768px;
$screen-xs: 480px;


$colour-primary :  #25395B;
$colour-primary-lighter : #a5b9da;
$colour-gray : #5F5F5F;
$colour-secondary : #f7902a;
$font-lato : 'Lato', sans-serif;