.header {

	&-strip {
		background-color:#f6f6f6;
		color: $colour-primary;
		line-height: 35px;
		text-align:	right;

		@media screen and (max-width:991px) and (min-width:768px) {
			text-align: center;
		}

		@media screen and (max-width:767px) {
			display: none;
		}

		span {
			margin-left:5px;
			margin-right:5px;

			i {
				margin-left:5px;
				argin-right:5px;
			}
		}
	}

	&-menu {
		margin: 0 auto;
		background: #fff;
		transition: box-shadow .3s ease, background-color .3s ease;
		box-shadow: 0 2px 2px 0 rgba(0,0,0,0.04);

		.menu {

			img {
				max-width: 68%;
				height:auto;
				width:100%;
			}

			 a.logo {
				display: inline-block;
				padding: 1em 3em 0.5em 0;
				float: left;
				border-bottom: 0;
				position: absolute;
				max-width:222px;

				@media screen and (max-width:1199px) and (min-width:992px) {
					left: 15px;
				}

				@media screen and (max-width:991px) and (min-width:768px) {
					position: relative;
					display: block;
					text-align: center;
					width: 100%;
					padding-left: 0;
					padding-right: 0;
					margin-bottom:0;
					max-width:100%;

					img {
						max-width:100px;
					}
				}

				@media screen and (max-width:767px) {
					padding: 10px;
				}

				img {
					@media screen and (max-width: 1069px) and (min-width:992px) {
					    max-width: 46%;
					    top:10px;
					    position:relative;
					}

					@media screen and (max-width:767px) {
						max-width:60px;
					}
				}
			}

			.menu-mobile {
				display: none;
				padding: 20px;
				border-bottom: 0 !important;

				@media screen and (max-width:767px) {
					padding:5px;
				}

				&:hover,&:active,&:focus  {
					border-bottom:0;
					text-decoration:none;
				}
			
				&:after {
					content: "+";
					font-size: 2.5rem;
					padding: 0;
					float: right;
					position: relative;
					top: 50%;
					-webkit-transform: translateY(-5%);
					transform: translateY(-5%);
				}
			}

			.menu-dropdown-icon:before {
				content: "+";
				display: none;
				cursor: pointer;
				float: right;
				padding: 10px 15px;
				background: rgb(246, 246, 246);
				color: $colour-primary;

				@media screen and (max-width:767px) {
					display:block;
				}
			}
			& > ul {
				list-style: none;
				padding: 0;
				position: relative;
				box-sizing: border-box;
				clear: right;
				margin-bottom: 0;
				float: right;
			
				&:before,
				&:after {
					content: "";
					display: table;
				}

				& > ul:after {
					clear: both;
				}

				& > li {
					float: left;
					background: #fff;
					padding: 0;
					margin: 0;
					line-height:60px;

					@media screen and (max-width:767px) {
						line-height:20px;
					}

					&.active,
					&:hover {
						background: #f6f6f6;
					}

					a {
						text-decoration: none;
						padding: 21px 30px;
						display: block;
						border-bottom: 0;
						font-size:15px;

						@media screen and (max-width:991px) and (min-width:768px) {
							text-decoration: none;
							padding: 15px 25px;
							display: block;
							border-bottom: 0;
							font-size: 13px;
						}

						@media screen and (max-width:767px) {
							padding: 10px;
						}

						&:hover {
							border-bottom: 0;
						}
					}
					& > ul {
						display: none;
						width: 100%;
						background: #f6f6f6;
						padding: 20px;
						position: absolute;
						z-index: 99;
						left: 0;
						margin: 0;
						list-style: none;
						box-sizing: border-box;

						&:before,
						&:after {
							content: "";
							display: table;
						}

						& > ul:after {
							clear: both;
						}

						& > li {
							margin: 0;
							padding-bottom: 0;
							list-style: none;
							width: 25%;
							background: none;
							float: left;
							line-height:1;

							& a {
								color: #777;
								padding: .2em 0;
								width: 95%;
								display: block;
								border-bottom: 1px solid #ccc;
								font-size:14px;

								&:hover {
									color: darken($colour-primary,10%);
								}
							}
						}

						&.normal-sub {
							width: 220px;
							left: auto;
							padding: 10px 20px;
							display:none;

							@media screen and (max-width:767px) {
								width: 100%;
								position: relative;
								display: none;
							}

							& > li {
								width: 100%;

								a {
									border: 0;
									padding: 10px 0;
									line-height:1.2;
								}
							}
						}
					}
				}
			}
		}
	}
}






	/* ––––––––––––––––––––––––––––––––––––––––––––––––––
	Mobile style's
	–––––––––––––––––––––––––––––––––––––––––––––––––– */

	@media only screen and (max-width: 767px) {
	  .menu-container {
	    width: 100%;
	  }
	  .menu-container .menu {
	    display: block;
	  }
	  .menu-mobile {
	    display: block !important;
	    float: right;
	    padding: 20px 20px 0;

	    &:hover {
	    	border-bottom:0 !important;
	    }
	  }
	  .menu-dropdown-icon:before {
	    display: block;
	  }
	  .menu > ul {
	    display: none;
	    width: 100%;
	  }
	  .menu > ul > li {
	    width: 100%;
	    float: none;
	    display: block;
	    line-height: 19px;
	  }
	  .menu > ul > li a {
	    padding: 1.5em;
	    width: 100%;
	    display: block;
	  }
	  .menu > ul > li > ul {
	    position: relative;
	    padding: 0 40px;
	  }
	  .menu > ul > li > ul.normal-sub {
	    width: 100%;
	  }
	  .menu > ul > li > ul > li {
	    float: none;
	    width: 100%;
	    margin-top: 20px;
	  }
	  .menu > ul > li > ul > li:first-child {
	    margin: 0;
	  }
	  .menu > ul > li > ul > li > ul {
	    position: relative;
	  }
	  .menu > ul > li > ul > li > ul > li {
	    float: none;
	  }
	  .menu .show-on-mobile {
	    display: block;
	  }
	}
