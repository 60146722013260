.hero {
	&-homepage {
		height:400px;
        @media screen and (min-width:1024px) {
            height:600px;
        }
        @media screen and (min-width:1600px) {
            height:800px;
        }

		position:relative;

		// .carousel-overlay {
		// 	position:absolute;
		// 	width:100%;
		// 	height:100%;
		// 	background: rgba(9,23,47,0.5);
		// }

		.carousel-content {
			position:relative;
			top: 15%;
			left:0;
			right:0;
			max-width: 600px;
			text-align: center;
			padding: 0;
			display: block;
			margin: 0 auto;
			color: #fff;

			h1 {
				display: block;
				color: #fff;
				font-weight: 700;
				line-height: 45px;
				text-transform: uppercase;
				text-shadow: 1px 1px #000;
				margin-bottom:30px;
				letter-spacing:1px;

				@media screen and (max-width:767px) {
					font-size:28px;
					line-height: 30px;

				}
			}
		}
		.slick-arrow {
			position: absolute;
			top: 46%;
			z-index: 1;
			background: none;
			border: 0;
			font-size: 40px;
			color: #fff;

			@media screen and (max-width:767px) {
				display: none;
			}

			&.slick-prev {
				left: 20px;
			}

			&.slick-next {
				right: 20px;
			}
		}
	}

	&-page {
		margin-bottom:40px;
		@include cover-image();
        min-height:240px;
        background-repeat:no-repeat;
        background-position: center;
        background-attachment: cover;
        @media screen and (min-width:768px) {
	        min-height:400px;
        }
        @media screen and (min-width:992px) {
            min-height:500px;
        }
        @media screen and (min-width:1440px) {
            min-height:700px;
        }
		position:relative;
		text-align: center;

		// .hero-overlay {
		// 	position:absolute;
		// 	width:100%;
		// 	height:100%;
		// 	background: rgba(9,23,47,0.5);
		// }

		.hero-content {
			padding: 60px 0;

			h1 {
				text-transform: uppercase;
				font-weight: 700;
				text-shadow: 1px 1px $colour-primary;
				font-size: 40px;
				color:#fff;
			}

			p {
				font-size: 17px;
				color: #f7f7f7;
				margin-top: 30px;
			}

			a {
				color:$colour-secondary;

				&:hover {
					border-bottom-color:$colour-secondary;
				}
			}
		}
	}

    &-blog-page {
        height: auto;
        min-height: auto;
    }
}
.home-carousel-hero {
	@include cover-image();
	height:400px;
	background-repeat:no-repeat;
	background-position: center center;
    @media screen and (min-width:1024px) {
        height:600px;
    }
    @media screen and (min-width:1600px) {
        height:800px;
    }
}
.contact-us-hero {
	background-size: cover;
	background-attachment: local;
	@media screen and (min-width:1600px) {
		background-position: center -170px;
	}
}
.about-us-hero {
	background-size: cover;
	background-attachment: local;
	@media screen and (min-width:1600px) {
		background-position: center -170px;
	}
}
.move-hero-up {
	@media screen and (min-width:1200px) {
		background-position: center 80%;
	}
	@media screen and (min-width:1600px) {
		background-position: center 60%;
	}
}
