/* =================================================================== */
/* == Page Layout ==================================================== */
/* =================================================================== */

html, body {
    position:relative;
    height:100%;
    width:100%;
    font-family: $font-lato;
    font-size:14px;
    color: $colour-gray;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* =================================================================== */
/* == Generic Fixes ================================================== */
/* =================================================================== */

/* This element causes scrollbars. It isn't needed... so just hide it! */
#fb-root { display:none; }

/* Give a google maps wrapper this class to stop bootstrap breaking the styles */
.google-map {
    * { box-sizing: content-box; }
    label { width: auto; display:inline; }

    iframe {
        pointer-events: none; 
    }
}


/* Fix for HTML dividers covering edit links */
.html-divider {
    margin:0!important;
    float:left!important;
}

::selection {
    color: #a5b9da;
    background: $colour-primary;
}

.affix {
    position: fixed;
    top: 0;
    z-index: 9999999;
    width: 100%;

    @media screen and (max-width:991px) and (min-width:768px) {
        .logo {
            display:none !important;
        }
    }
}
