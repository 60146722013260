@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	color: $colour-primary;

	&.seperators {
		background: url(/themes/bcselectrics/assets/img/seperator.png) no-repeat;
		background-size: contain;
		background-position: 50%;
		margin-top: 0.5em;
		margin-bottom: 1em;
		position: relative;

		@media screen and (max-width:991px) {
			background:none;
		}
	}
}

h1 {

}

h2 {
	position: relative;
	text-align: center;
	font-size:34px;
	font-weight:300;
}

h3 {

}

p {

}

a {
	color: $colour-primary;

	&:hover {
		text-decoration: none;
		border-bottom: dotted 1px $colour-primary;
	}
}
